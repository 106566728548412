export const AGREE_TO_MAP_POLICY_ERR_MSG = 'Must agree to MAP policy terms';
export const ALPHA_REGEX = /^[a-zA-Z]+$/i;
export const BUSINESS_NAME_MAX_LENGTH_ERR_MSG = 'Business name must be less than 256 characters';
export const BUSINESS_NAME_REQ_ERR_MSG = 'Business name required';
export const CHAR_MAX_LENGTH = 255;
export const CITY_MAX_LENGTH_ERR_MSG = 'City must be less than 256 characters';
export const CITY_REQ_ERR_MSG = 'City required';
export const COMMENTS_MAX_LENGTH_ERR_MSG = 'Comments must be less than 256 characters';
export const COMPANY_NAME_REQ_ERR_MSG = 'Company name required';
export const DBA_NAME_MAX_LENGTH_ERR_MSG = 'DBA name must be less than 256 characters';
export const DBA_NAME_REQ_ERR_MSG = 'DBA name required';
export const EMAIL_MAX_LENGTH_ERR_MSG = 'Email must be less than 256 characters';
export const EMAIL_PATTERN_ERR_MSG = 'Invalid email';
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_REQ_ERR_MSG = 'Email required';
export const FILE_UPLOAD_INVALID_TYPE_ERR_MSG = 'Invalid file format';
export const FILE_UPLOAD_MAX_SIZE_ERR_MSG = 'File cannot exceed 2MB';
export const FILE_UPLOAD_REQ_ERR_MSG = 'File required';
export const FIRST_NAME_ALPHA_ERR_MSG = 'First name must be alphabetical only';
export const FIRST_NAME_MAX_LENGTH_ERR_MSG = 'First name must be less than 256 characters';
export const FIRST_NAME_REQ_ERR_MSG = 'First name required';
export const FORM_ERR_MSG = 'Please correct the errors above';
export const FREE_SHIPPING_MSG = 'Free Shipping on orders $250 and over';
export const FULLFILLMENT_CENTER_MAX_LENGTH_ERR_MSG = 'Fulfillment center name must be less than 256 characters';
export const FULLFILLMENT_CENTER_REQ_ERR_MSG = 'Fulfillment center name required';
export const LAST_NAME_ALPHA_ERR_MSG = 'Last name must be alphabetical only';
export const LAST_NAME_MAX_LENGTH_ERR_MSG = 'Last name must be less than 256 characters';
export const LAST_NAME_REQ_ERR_MSG = 'Last name required';
export const SALESFORCE_NAME_ERR_MSG = `Names may contain only alpha-numerics and these symbols . - '`;
export const SALESFORCE_NAME_REGEX = /^[a-z0-9.\-']+$/i;
export const PHONE_INVALID_ERR_MSG = 'Invalid phone number';
export const PHONE_MAX_LENGTH_ERR_MSG = 'Phone number must be less than 256 characters';
export const PHONE_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/i;
export const PHONE_REQ_ERR_MSG = 'Phone number required';
export const PRACTICE_TYPE_REQ_ERR_MSG = 'Practice type required';
export const PRIMARY_ADDRESS_REQ_ERR_MSG = 'Must have a primary address';
export const PW_MATCH_ERR_MSG = 'Password does not match';
export const PW_MAX_LENGTH = 40;
export const PW_MAX_LENGTH_ERR_MSG = 'Password is too long (maximum is 40 characters)';
export const PW_MIN_LENGTH = 8;
export const PW_MIN_LENGTH_ERR_MSG = 'Password must be at least 8 characters';
export const PW_REQ_ERR_MSG = 'Password required';
export const PW_VERIFY_ERR_MSG = 'Please verify your password';
export const SECONDARY_PHONE_MAX_LENGTH_ERR_MSG = 'Secondary phone number must be less than 256 characters';
export const SELECT_REQ_ERR_MSG = 'Selection required';
export const SPECIALTY_REQ_ERR_MSG = 'Specialty required';
export const STATE_REQ_ERR_MSG = 'State required';
export const STOREFRONT_NAME_MAX_LENGTH_ERR_MSG = 'Storefront name must be less than 256 characters';
export const STOREFRONT_NAME_REQ_ERR_MSG = 'Storefront name required';
export const STOREFRONT_REQ_ERR_MSG = 'Storefront required';
export const STREET_ADDRESS_MAX_LENGTH_ERR_MSG = 'Address must be less than 256 characters';
export const STREET_ADDRESS_REQ_ERR_MSG = 'Address required';
export const URL_REQ_ERR_MSG = 'Invalid URL';
export const WEBSITE_MAX_LENGTH_ERR_MSG = 'Website must be less than 256 characters';
export const WEBSITE_REGEX = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
export const WEBSITE_REGEX_ERR_MSG = 'Invalid website';
export const ZIP_PATTERN_ERR_MSG = 'Zip code must be 5 digits';
export const ZIP_REQ_ERR_MSG = 'Zip code required';
export const ERRORS_BY_CODE = {
  invalid_grant: {
    code: 'invalid_grant',
    message: 'The email or password you used is incorrect',
  },
  invalid_signup: {
    code: 'invalid_signup',
    message: 'A user already exists with that email. Please login to continue registration',
  },
  invalid_password: {
    code: 'invalid_password',
    // https://auth0.com/docs/libraries/common-auth0-library-authentication-errors#sign-up
    message: 'The password you used does not meet the requirements',
  },
  default: {
    code: 'default',
    message: 'Oops! Something went wrong...',
  },
};
export const REGISTRATION_STATUS = {
	INCOMPLETE: 'INCOMPLETE',
	COMPLETE: 'COMPLETE',
};
export const PRACTITIONER_STATUS = {
  INCOMPLETE: 'INCOMPLETE',
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  ACTIVE_EXPRESS: 'ACTIVE_EXPRESS',
  UNAPPROVED: 'UNAPPROVED',
};
export const PRACTITIONER_STATUS_MESSAGE = {
  INCOMPLETE: {
    SHORT: 'Incomplete',
    LONG: 'Your application is incomplete. [Continue application](/account/register) to get access to our products.',
  },
  PENDING: {
    SHORT: 'Pending',
    LONG: 'Your new account is being processed. Please allow us 1-2 business days to review your application. [Contact customer support](/contact-us) if you have any questions.',
  },
  ACTIVE: {
    SHORT: 'Active',
    LONG: 'Account Status: Active'
  },
  ACTIVE_EXPRESS: {
    SHORT: 'Active Express',
    LONG: 'Your application is incomplete but you have access to our products. [Continue application](/account/register) when you are ready.',
  },
  UNAPPROVED: {
    SHORT: 'Unapproved',
    LONG: 'There is an issue with your account. [Contact customer support](/contact-us) or your sales rep for more information'
  },
};
export const CUSTOMER_TYPE = {
  EMPLOYEE: 'NN Employee',
  CONSUMER: 'Pro',
};

export const MIN_PURCHASE_QUANTITY = 1;
export const MAX_PURCHASE_QUANTITY = 1000;
